<template>
  <el-menu class="navbar" mode="horizontal" :style="{ height }">
    <div class="lines"></div>
    <div class="navbar-left" :style="{ height }">
      <div class="logo-container row no-gutters align-items-center">
        <div class="col-auto">
          <img :src="urlData.logo" alt="" :style="logoStyle">
        </div>
        <div class="col project-title">
          <div class="line1" :style="titleLine1Style">{{ urlData.name }}</div>
          <div class="line2" :style="titleLine2Style">{{ urlData.logo_name }}</div>
        </div>
      </div>
    </div>
    <div class="right-menu row no-gutters" :style="rightMenuStyle">
      <div class="notice" v-if="latestNotice.notice_title">
        <img src="../../../assets/images/announcement.png">
        <marquee style="width: 100%">
          <span style="color: #d9001b;">{{ latestNotice.notice_title }}</span>
        </marquee>
        <div class="el-icon-arrow-right go" @click="$router.push({ name: 'enterpriseNotice' })">
        </div>
      </div>
      <div class="avatar-wrapper col-auto">
        <img class="user-avatar" :src="avatar" :style="avatarStyle" />
      </div>
      <div class="col-auto nickname" v-if="shopInfo.shop_type === 1 && nickname" :style="nicknameStyle">
        <p style="margin-bottom: 5px;">{{ nickname }}</p>
        <p style="margin-bottom: 0;" v-if="shopInfo.shop_type !== 3 && shopInfo.shop_type !== 2">{{ username }}</p>
      </div>
      <div class="col-auto nickname" v-if="shopInfo.shop_type !== 1 && shopName" :style="nicknameStyle">
        <p style="margin-bottom: 5px;">{{ shopName }}</p>
      </div>
      <div class="loginOut col-auto" @click="logout" :style="logoutStyle">
        <i class="el-icon-switch-button"></i>
      </div>
    </div>
  </el-menu>
</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumb from "@/components/Breadcrumb";
import Hamburger from "@/components/Hamburger";
import Screenfull from "@/components/Screenfull";
import LangSelect from "@/components/LangSelect";
import ThemePicker from "@/components/ThemePicker";
import Resize from "../mixin/resize";
import imgUrl from '@/assets/logo-base.png';
import DefaultAvatar from "../../../assets/logo_images/icon-noface.jpg";
import Storage from "@/utils/storage";
import { getLatestNotice } from '@/api/info';
import { getDomainByBasicInformation } from "@/api/account"
import { getCurrentMainDomain } from "@/utils/domain";
export default {
  components: {
    Breadcrumb,
    Hamburger,
    Screenfull,
    LangSelect,
    ThemePicker,
  },
  data () {
    return {
      urlData: {
        logo: '',
        logo_name: '',
        name: '',
      },
      fromEnterUser: null,
      latestNotice: {}
    };
  },
  mixins: [Resize],
  computed: {
    ...mapGetters(["sidebar", "user", "shopInfo"]),
    height () {
      return `${80 * this.scale}px`;
    },
    logoStyle () {
      return {
        height: `${35 * this.scale}px`,
      };
    },
    titleLine1Style () {
      return {
        fontSize: `${18 * this.scale}px`,
        marginTop: `${4 * this.scale}px`,
      };
    },
    titleLine2Style () {
      return {
        fontSize: `${12 * this.scale}px`,
        marginTop: `${4 * this.scale}px`,
      };
    },
    rightMenuStyle () {
      return {
        height: this.height,
        paddingRight: `${24 * this.scale}px`,
      };
    },
    nickname () {
      return (
        (this.fromEnterUser
          ? this.fromEnterUser.nickname
          : this.user.nickname) || ""
      );
    },
    shopName () {
      return (
        (this.fromEnterUser
          ? this.fromEnterUser.shop_name
          : this.user.shop_name) || ""
      );
    },
    username () {
      return (
        (this.fromEnterUser
          ? this.fromEnterUser.username
          : this.user.username) || ""
      );
    },
    nicknameStyle () {
      return {
        fontSize: `${18 * this.scale}px`,
        padding: `0 ${12 * this.scale}px`,
      };
    },
    logoutStyle () {
      return {
        fontSize: `${24 * this.scale}px`,
      };
    },
    avatar () {
      return (
        (this.fromEnterUser ? this.fromEnterUser.face : this.user.face) ||
        DefaultAvatar
      );
    },
    avatarStyle () {
      return {
        width: `${48 * this.scale}px`,
        height: `${48 * this.scale}px`,
      };
    },

  },
  mounted () {
    if (location.host.indexOf('192.168') === -1 && location.host.indexOf('sshlqf') === -1) {
      const endUrl = getCurrentMainDomain() // 获取当前地址栏后缀主域名地址
      getDomainByBasicInformation(endUrl).then(res => {
        this.urlData = {
          logo: res.logo,
          logo_name: res.logo_name || '',
          name: res.name || ''
        }
      })
    } else {
      this.urlData = {
        logo: imgUrl,
        logo_name: 'ShanShui Internet',
        name: '山水互联',
      }
    }
    this.getLatestNotice && this.getLatestNotice()
    // 刷新浏览器，企业跳转到站点应该获取站点的用户信息而非cookie里面的seller_user
    if (sessionStorage.getItem("from_enter_seller_user")) {
      this.fromEnterUser = JSON.parse(
        sessionStorage.getItem("from_enter_seller_user")
      );
    }
  },
  methods: {
    async getLatestNotice () {
      const res = await getLatestNotice()
      this.latestNotice = res
    },
    toggleSideBar () {
      this.$store.dispatch("toggleSideBar");
    },
    logout () {
      this.$confirm("确定退出吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let user = Storage.getItem("seller_user");
        let fromEnterSellerUser = JSON.parse(
          sessionStorage.getItem("from_enter_seller_user")
        );
        let member_type = (this.MixinIsFormEnterprise
          ? fromEnterSellerUser || user
          : user
        ).member_type;
        this.$store.dispatch("logoutAction").then(() => {
          switch (member_type) {
            case 2:
              window.location.href = "/login";
              break;
            case 3:
              window.location.href = "/login?type=enterprise";
              break;
            case 4:
              window.location.href = "/login?type=supplier";
              break;
            case 5:
              window.location.href = "/login?type=combo-card-shop";
              break;
            default:
              window.location.href = "/login";
              break;
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../../plugins/variables";

.navbar {
  //height: 60px;
  height: 80px;
  border-radius: 0 !important;
  //background-color: lighten(#001529, 5%);
  //background-color: #262F3E !important;
  background-color: #fff;
  //border-bottom: 0 !important;
  //box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  //border-bottom: 1px solid #B8C2CC !important;
  z-index: 99;

  .lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    pointer-events: none;

    &::before {
      content: " ";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 90%;
      transform: translate(-85%, -50%) rotate(24deg);
      background: rgba(255, 255, 255, 0.03);
    }
  }

  .navbar-left {
    float: left;
    display: flex;
    height: 79px;
    align-items: center;
    padding-left: 15px;

    .logo-container {
      height: 100%;

      img {
        height: 35px;
      }

      .project-title {
        //color: #fff;
        color: #333;
        padding-left: 8px;

        .line1 {
          font-weight: bold;
          font-size: 18px;
          line-height: 1;
          margin-top: 4px;
        }

        .line2 {
          margin-top: 4px;
          font-size: 12px;
          transform-origin: top left;
          //transform: scale(0.9);
        }
      }
    }
  }

  .hamburger-container {
    padding: 0 10px;
  }

  .breadcrumb-container {
    float: left;

    ::v-deep {

      .el-breadcrumb__separator,
      .el-breadcrumb__item .el-breadcrumb__inner a {
        font-size: 16px;
        //color: #fff !important;
        color: #333 !important;
      }

      .el-breadcrumb__item .el-breadcrumb__inner .no-redirect {
        font-size: 16px;
        //color: #fff !important;
        color: #333 !important;
      }
    }
  }

  .right-menu {
    display: flex;
    align-items: center;
    float: right;
    height: 79px;
    padding-right: 16px;

    ::v-deep svg {
      //fill: #fff !important;
      fill: #333 !important;
    }

    &:focus {
      outline: none;
    }

    .notice {
      display: flex;
      align-items: center;
      width: 30vw;
      margin-right: 20px;

      img {
        width: 17px;
        height: 17px;
        margin-right: 2px;
      }

      .go {
        width: 17px;
        height: 17px;
        border-radius: 50%;
        background-color: #13227a;
        margin-left: 2px;
        color: #fff;
        line-height: 17px;
        text-align: center;
        cursor: pointer;
      }
    }

    .right-menu-item {
      display: inline-block;
      margin: 0 15px;
    }

    .avatar-container {
      height: 60px;
      margin-right: 30px;
    }

    .avatar-wrapper {
      cursor: pointer;
      position: relative;
      font-size: 9px;
      //color: #fff;
      color: #333;

      .user-avatar {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin-right: 7.5px;
        object-fit: cover;
      }

      .el-icon-caret-bottom {
        position: absolute;
        right: -20px;
        top: 25px;
        font-size: 24px;
      }

      ::v-deep .el-icon-caret-bottom {
        color: $nav_text_active !important;
      }
    }

    .nickname {
      font-size: 18px;
      line-height: 1;
      padding: 0 12px;
    }

    .loginOut {
      text-align: center;
      margin-bottom: 3px;
      //color: #fff;
      color: #333;
      font-size: 24px;
      cursor: pointer;
    }

    .loginOut img {
      height: 16px;
      width: 16px;
      cursor: pointer;
    }
  }
}
</style>
